<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form :form="form">
          <a-form-item label="标题">
            <a-input
              v-model="title"
            />
          </a-form-item>
          <a-form-item label="id">
            <a-input
              v-model="key"
              disabled
            />
          </a-form-item>
        </a-form>
        <div class="home">
          <div id="editor" class="editor"></div>
          <a-button type="primary" @click="submit" class="btn">提交</a-button>
        </div>
      </div>
    </a-card>
  </page-header-wrapper>
</template>

<script>
// 引入 wangEditor
import E from 'wangeditor'
import { getAgreement, postAgreement, editAgreement } from '@/api/agreements'
export default {
  data() {
    return {
      editor: null,
      editorData: '',
      editorContent: '',
      id: '',
      form: this.$form.createForm(this, { name: 'title' }),
      title: '',
      key: '',
      editStatus: false
    }
  },
  mounted() {
    this.id = this.$route.query.id
    if (this.id) {
        this.getInfo()
        this.editStatus = true
    } else {
      this.showWang()
      this.editStatus = false
    }
  },
  methods: {
    submit() {
          if (this.title && this.editorContent && this.key) {
            const requestParameters = {
              'title': this.title,
              'content': `${this.editorContent}`,
              'id': this.key
            }
            if (!this.editStatus) {
               postAgreement(requestParameters)
              .then((res) => {
                 this.$router.push({
                    path: '/systemSetup/agreement/list'
                  })
                 this.$message.info('修改成功')
              }).catch((res) => {
                 this.$message.info('修改失败')
              })
            } else {
                editAgreement(requestParameters)
                .then((res) => {
                  this.$router.push({
                      path: '/systemSetup/agreement/list'
                    })
                  this.$message.info('修改成功')
                }).catch((res) => {
                  this.$message.info('修改失败')
                })
            }

          } else {
            this.$message.info('请填写完整')
          }
    },
    getInfo() {
      const params = {
        id: this.id
      }
      getAgreement(params)
        .then((res) => {
          this.editorContent = res.content
          this.title = res.title
          this.key = res.id
          this.showWang()
        })
        .catch((res) => {})
    },

    showWang() {
      var editor = new E('#editor')
      editor.customConfig.onchange = (html) => {
        this.editorContent = html
      }
      editor.customConfig.uploadImgShowBase64 = true
      editor.customConfig.linkImgCallback = function (url) {
      }
      editor.customConfig.zIndex = 1
      editor.create()
      editor.txt.html(this.editorContent) 
    }
  }
}
</script>

<style lang="less">
.home {
  .btn {
    margin-top: 15px;
    width: 100%;
  }
}
</style>
